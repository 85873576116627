import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/apps/teo/components/TeoPermission/TeoPermissionProvider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Button/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/CardActions/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/CardContent/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/CardHeader/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/CircularProgress/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Divider/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Paper/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Stack/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/AuthProvider/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Code/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/CopyToClipboard/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Form/FormAutocomplete/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Form/FormDatePicker/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Form/FormSearch/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Form/FormSelect/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Form/FormTextField/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Form/FormTypesenseAutocomplete/FormTypesenseAutocomplete.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Link/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Page/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/Popup/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2829979463/src/packages/ui-shared/components/src/lib/ThemeProvider/index.tsx")